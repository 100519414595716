<template>
  <div class="custom-culture">
        <div class="custom-container">
                <div class="back-home">
                    <div class="home-buttom" style="fontSize : 15px;">
                        <router-link :to="'/plan'">
				                <i class="fas fa-arrow-left"></i>&nbsp;&nbsp;
				                Go back
			                  </router-link>
                      </div>
                  </div>

                  <div class="content-box">
                      <div class="topic" data-aos="zoom-out" data-aos-delay="300">
                          <p>{{custom}}</p>
                      </div>
                      <div class="text" v-html="text" data-aos="zoom-out" data-aos-delay="600"></div>

                      <div class="text-box" data-aos="zoom-out" data-aos-delay="900">
                          <div>
                              <div class="search-img"><div class="picture02"><img :src="picture02" alt="picture02"></div></div>
                              <div class="mountian-img"><div class="picture01"><img :src="picture01" alt="picture01"></div></div>
                              <div class="text-red-box">
                                  <p class="topic-red-box">{{topic2}}</p>
                                  <p class="text-red-box" v-html="text3"></p>
                              </div>
                              
                          </div>
                            
                      </div>
  
                </div>
        </div>
        

  </div>
</template>

<script>
import('aos').then(AOS => AOS.init());
import picture01 from '../../assets/img/themes/Path 13015.png';
import picture02 from '../../assets/img/themes/Group 640.png';

export default {
  name: "CustomCulture",
  custom : "",

  data() {
    return {
      picture01 : picture01,
      picture02 : picture02,
      custom : "Customs & Culture",
      text : `<p>Mongolian culture is based upon thousands of years of living a nomadic life. This as well as our strong sense for family and country have formed our customs and social norms.</p>
            <p>While Ulaanbaatar may seem like any other major city, it’s in the vast outdoors where true cultures and customs can be experienced. In the steppes and mountains, horses play an important role in the daily life of Mongols. Festivals such as Naadam where horse racing, wrestling and archery contests are defining customs and identity of the people of Mongolia. Similarly, music, fine arts and literature is also a big part of our culture.</p>`,
      text2 : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
      topic2 : "How to behave",
      text3 : `<p>Mongolians can be superstitious at times and have certain specific etiquettes or customs but foreigners are not expected to learn or practice them. The locals won’t find offence. However, like in most other Asian countries, it is important to be polite and courteous at all times.
                <br><br>For example, when staying with a host, accept whatever is offered and smile. Another important set of etiquettes to keep in mind is that you do not point with your finger, touch someone’s head or put your feet up.
                <br>It’s a good idea to observe how locals interact with each other and take cues on body gestures. Above all, practice common sense and some level of conservative behavior.</p>`,
    }
  },
};
</script>

<style lang="scss" scoped>

  .custom-culture {
      .custom-container {
              width: 100%;
              height: 100%;
              .back-home {
                    display: flex;
                    width: 100%;
                    height: 15%;
                    left: 10%;
                    .router-link-active {
                        color: #E13036;
                        font-weight: bold;
                        }
                    .home-buttom {
                        color: #E13036;
                        margin-left: 4%;
                        padding-top: 2%;
                      }  
              }
              .content-box {
                position: relative;
                left: 18.6%;
                right: 18.6%;
                top: 0%;
                width: 57%;
                height: 87.90%;               
                .topic {
                    font-weight: bold;
                }
                .text {
                    margin-top: 5%;
                }
                .text-box {
                    position: absolute;
                    bottom: 0%;
                    width: 100%;
                    background-color: #E13036;
                    .search-img {
                        margin-top: 2%;
                        margin-left: 2%;
                    }
                    .mountian-img {
                        position: absolute;
                        right: 2%;
                    }
                    .text-red-box {
                        color: white;
                        padding-top: 2%;
                    }
                }
              }
      }
  }

  @media only screen and (min-width: 1280px) {
      .custom-culture {
      display: flex;
      height: 100%;
      .custom-container {
              .back-home {
                      position: static;
                      width: 100%;
                      height: 12.10%;
                      .router-link-active {
                          color: #E13036;
                          font-weight: bold;
                      }
                      .home-buttom {
                          color: red;
                          margin-left: 4%;
                          padding-top: 2%;
                          font-size: 20px;
                      }  
              }
              .content-box {             
                .topic {
                    font-size: 20px;
                    font-weight: bold;
                }
                .text {
                    font-size: 10px;
                }
                .text-box {
                    padding: 5.07% 6.28% 5.07%;
                    .search-img {
                        margin-top: 2%;
                        margin-left: 2%;
                        img {
                            width: 5%;
                        }
                    }
                    .mountian-img {
                        position: absolute;
                        padding-left: 50%;
                        img {
                            width: 75%;
                        }
                    }
                    .text-red-box {
                        max-height: 25%;
                        color: white;
                        .topic-red-box {
                            font-size: 20px;
                        }
                        .text-red-box {
                            max-width: 100%;
                            font-size: 10px;
                            
                        }
                    }
                }
              }
      }
  }

  }
  @media only screen and (min-width: 1920px) {
      .custom-culture {
      height: calc(1080px - 102px);
      .custom-container {
              .back-home {
                      position: static;
                      width: 100%;
                      height: 12.10%;
                      .router-link-active {
                          color: #E13036;
                          font-weight: bold;
                      }
                      .home-buttom {
                          color: red;
                          margin-left: 4%;
                          padding-top: 2%;
                          font-size: 20px;
                      }  
              }
              .content-box {             
                .topic {
                    font-size: 50px;
                    font-weight: bold;
                }
                .text {
                    font-size: 15px;
                }
                .text-box {
                    height: 55%;
                    .search-img {
                        margin-top: 2%;
                        margin-left: 2%;
                        img {
                            width: 5%;
                        }
                    }
                    .mountian-img {
                        position: absolute;
                        padding-left: 50%;
                        img {
                            width: 75%;
                            padding-left: 2%;
                        }
                    }
                    .text-red-box {
                        color: white;
                        .topic-red-box {
                            font-size: 2.29vw;
                            margin: 0 auto 0.6em;
                        }
                        .text-red-box {
                            max-width: 90%;
                            font-size: 15px;
                            
                        }
                    }
                }
              }
      }
  }

  }

</style>